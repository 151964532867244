<script setup>
</script>

<template>
<div class="picture-box"></div>
</template>

<style scoped>
.picture-box {
    min-width: 150px;
    min-height: 150px;
    background: url("../public/images/profile_picture.jpg");
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #eeeeee;
}
</style>